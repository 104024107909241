import UtilService from "../../service/UtilService";
import { useImmer } from "use-immer";
import { useEffect } from "react";

const Component = ({ config, onChange }) => {
  const scoreConfigTemplate = { countryCodes: [""], scoreOnMatch: 0.0 };
  const [data, updateData] = useImmer(config);

  useEffect(() => {
    onChange(data);
  }, [data]);

  useEffect(() => {
    updateData(config);
  }, [config]);

  const addScore = () => {
    updateData((d) => {
      d.scores.push(structuredClone(scoreConfigTemplate));
    });
  };

  const removeScore = (index) => {
    updateData((d) => {
      d.scores.splice(index, 1);
    });
  };

  return (
    <>
      <table className="config-table table-with-button">
        <thead>
          <tr>
            <th>Country codes</th>
            <th>Score</th>
            <th>
              <a className="fa-solid fa-plus" onClick={() => addScore()}></a>
            </th>
          </tr>
        </thead>
        <tbody>
          {data.scores.map((score, index) => (
            <tr key={index}>
              <td>
                <input
                  type="text"
                  value={UtilService.arrayToCsv(score.countryCodes)}
                  onChange={(e) =>
                    updateData((d) => {
                      d.scores[index].countryCodes =
                        UtilService.csvToStringArray(e.target.value);
                    })
                  }
                />
              </td>
              <td>
                <input
                  type="number"
                  value={score.scoreOnMatch}
                  min="0"
                  step="0.1"
                  onChange={(e) =>
                    updateData((d) => {
                      d.scores[index].scoreOnMatch = e.target.value;
                    })
                  }
                />
              </td>
              <td>
                <a
                  className="fa-solid fa-trash-can"
                  onClick={() => removeScore(index)}
                ></a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default Component;
