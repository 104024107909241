import { useImmer } from "use-immer";
import ApiConnector from "../connector/ApiConnector";
import { useEffect } from "react";
import DayOfWeekCheckboxes from "../component/DayOfWeekCheckboxes";
import Note from "../component/Note";
import UtilService from "../service/UtilService";

const Component = (props) => {
  const [data, updateData] = useImmer(null);
  const [newEntry] = useImmer({
    licencePlate: null,
    merchantCode: null,
    terminalId: null,
    dayOfWeeks: null,
    timeValidFrom: null,
    timeValidTo: null,
  });

  useEffect(() => {
    loadLatestGeneralConfig();
  }, []);

  const loadLatestGeneralConfig = () => {
    ApiConnector.getLatestGeneralConfig().then((t) => {
      const autoDeclineConfig = t.generalConfig.autoDeclineConfig;
      autoDeclineConfig.autoDeclineRules =
        autoDeclineConfig.autoDeclineRules.sort((a, b) =>
          UtilService.localeCompare(a.terminalId, b.terminalId),
        );
      autoDeclineConfig.autoDeclineRules =
        autoDeclineConfig.autoDeclineRules.sort((a, b) =>
          UtilService.localeCompare(a.merchantCode, b.merchantCode),
        );
      autoDeclineConfig.autoDeclineRules =
        autoDeclineConfig.autoDeclineRules.sort((a, b) =>
          UtilService.localeCompare(a.licencePlate, b.licencePlate),
        );
      updateData(t);
    });
  };

  const createGeneralConfig = async () => {
    const isConfigValid =
      data.generalConfig.autoDeclineConfig.autoDeclineRules.filter(
        (rule) => !rule.merchantCode && !rule.licencePlate && !rule.terminalId,
      ).length === 0;
    if (!isConfigValid) {
      window.alert("Mandatory fields are not filled.");
      return;
    }

    const result = window.confirm(
      "New general config will be created and set as active.",
    );
    if (!result) {
      return;
    }

    const newConfig = await ApiConnector.createGeneralConfig(data);
    updateData(newConfig);
  };

  return (
    <>
      <header className="subheader">
        <h1>Admin - Auto decline</h1>
      </header>
      {data && (
        <main>
          <div className="row">
            <div className="col">
              <h2>Auto decline rules</h2>
            </div>
            <div className="col">
              <h2 className="float-right">
                <Note
                  noteTargetPath="generalConfig.autoDecline"
                  noteTargetId={data.id}
                />
                &nbsp;&nbsp;&nbsp;
                <a
                  className="fa-regular fa-floppy-disk"
                  onClick={createGeneralConfig}
                ></a>
              </h2>
            </div>
          </div>

          <table className="config-table-double table-scrollable table-with-button">
            <thead>
              <tr>
                <th>Licence plate</th>
                <th>Merchant code</th>
                <th>Terminal id</th>
                <th>Day of week</th>
                <th>From [local time]</th>
                <th>To [local time]</th>
                <th>
                  <a
                    className="fa-solid fa-plus"
                    onClick={(e) =>
                      updateData((d) => {
                        d.generalConfig.autoDeclineConfig.autoDeclineRules.unshift(
                          structuredClone(newEntry),
                        );
                      })
                    }
                  ></a>
                </th>
              </tr>
            </thead>
            <tbody>
              {data.generalConfig.autoDeclineConfig.autoDeclineRules.map(
                (r, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="text"
                        value={r.licencePlate || ""}
                        onChange={(e) =>
                          updateData((d) => {
                            d.generalConfig.autoDeclineConfig.autoDeclineRules[
                              index
                            ].licencePlate = e.target.value;
                          })
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={r.merchantCode || ""}
                        onChange={(e) =>
                          updateData((d) => {
                            d.generalConfig.autoDeclineConfig.autoDeclineRules[
                              index
                            ].merchantCode = e.target.value;
                          })
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={r.terminalId || ""}
                        onChange={(e) =>
                          updateData((d) => {
                            d.generalConfig.autoDeclineConfig.autoDeclineRules[
                              index
                            ].terminalId = e.target.value;
                          })
                        }
                      />
                    </td>
                    <td>
                      <DayOfWeekCheckboxes
                        breakLine={true}
                        dayOfWeekArray={r.dayOfWeeks || []}
                        onChange={(e) =>
                          updateData((d) => {
                            d.generalConfig.autoDeclineConfig.autoDeclineRules[
                              index
                            ].dayOfWeeks = e;
                          })
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="time"
                        value={r.timeValidFrom || ""}
                        onChange={(e) =>
                          updateData((d) => {
                            d.generalConfig.autoDeclineConfig.autoDeclineRules[
                              index
                            ].timeValidFrom = e.target.value;
                          })
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="time"
                        value={r.timeValidTo || ""}
                        onChange={(e) =>
                          updateData((d) => {
                            d.generalConfig.autoDeclineConfig.autoDeclineRules[
                              index
                            ].timeValidTo = e.target.value;
                          })
                        }
                      />
                    </td>
                    <td>
                      <a
                        className="fa-solid fa-trash-can"
                        onClick={(e) =>
                          updateData((d) => {
                            d.generalConfig.autoDeclineConfig.autoDeclineRules.splice(
                              index,
                              1,
                            );
                          })
                        }
                      ></a>
                    </td>
                  </tr>
                ),
              )}
            </tbody>
          </table>
        </main>
      )}
    </>
  );
};

export default Component;
