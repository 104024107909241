import { useImmer } from "use-immer";
import { useEffect } from "react";
import UtilService from "../../service/UtilService";
import DayOfWeekCheckboxes from "../DayOfWeekCheckboxes";

const Component = ({ config, onChange }) => {
  const [data, updateData] = useImmer(config);

  useEffect(() => {
    updateData(config);
  }, [config]);

  useEffect(() => {
    onChange(data);
  }, [data]);

  return (
    <>
      <table className="config-table">
        <thead>
          <tr>
            <th>Attribute</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Update on days</td>
            <td>
              <DayOfWeekCheckboxes
                dayOfWeekArray={data.updateAtDays}
                onChange={(e) =>
                  updateData((d) => {
                    d.updateAtDays = e;
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>Update on time [CET]</td>
            <td>
              <input
                type="time"
                value={data.updateAtTime}
                onChange={(e) =>
                  updateData((d) => {
                    d.updateAtTime = e.target.value;
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>History lookup [days]</td>
            <td>
              <input
                type="number"
                value={data.historyLookupDays}
                onChange={(e) =>
                  updateData((d) => {
                    d.historyLookupDays =
                      e.target.value < 0 ? e.target.value : 0;
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>New spot history lookup [days]</td>
            <td>
              <input
                type="number"
                value={data.newSpotHistoryLookupDays}
                onChange={(e) =>
                  updateData((d) => {
                    d.newSpotHistoryLookupDays =
                      e.target.value < 0 ? e.target.value : 0;
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>Semi-hotspot fraud quotient [at least]</td>
            <td>
              <input
                type="number"
                value={data.semiHotspotMinQuotient}
                step={0.001}
                onChange={(e) =>
                  updateData((d) => {
                    const val = e.target.value;
                    d.semiHotspotMinQuotient = val > 0 ? val : 0;
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>Hotspot fraud quotient [at least]</td>
            <td>
              <input
                type="number"
                value={data.hotspotMinQuotient}
                step={0.1}
                onChange={(e) =>
                  updateData((d) => {
                    const val = e.target.value;
                    d.hotspotMinQuotient = val > 0 ? val : 0;
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>Exclude merchants</td>
            <td>
              <input
                type="text"
                value={UtilService.arrayToCsv(data.excludeMerchants)}
                onChange={(e) =>
                  updateData((d) => {
                    d.excludeMerchants = UtilService.csvToStringArray(
                      e.target.value,
                    );
                  })
                }
              />
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default Component;
