import { useImmer } from "use-immer";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const Component = ({ config, onChange }) => {
  const [data, updateData] = useImmer(config);

  useEffect(() => {
    updateData(config);
  }, [config]);

  useEffect(() => {
    onChange(data);
  }, [data]);

  return (
    <>
      <table className="config-table">
        <thead>
          <tr>
            <th>Attribute</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              History lookup min <small>[days]</small>
            </td>
            <td>
              <input
                type="number"
                value={data.historyLookupDaysMin}
                onChange={(e) =>
                  updateData((d) => {
                    d.historyLookupDaysMin =
                      e.target.value < 0 ? e.target.value : 0;
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>
              History lookup max <small>[days]</small>
            </td>
            <td>
              <input
                type="number"
                value={data.historyLookupDaysMax}
                onChange={(e) =>
                  updateData((d) => {
                    d.historyLookupDaysMax =
                      e.target.value < 0 ? e.target.value : 0;
                  })
                }
              />
            </td>
          </tr>
        </tbody>
      </table>
      <table className="config-table">
        <thead>
          <tr>
            <th>Score</th>
            <th>σ x 0.5</th>
            <th>σ x 1.0</th>
            <th>σ x 1.5</th>
            <th>σ x 2.0</th>
            <th>Up to ∞</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Per max. recent volume</td>
            {data.scores.map((score, idx) => (
              <td key={idx}>
                <input
                  type="number"
                  value={data.scores[idx]}
                  onChange={(e) =>
                    updateData((d) => {
                      d.scores[idx] = e.target.value;
                    })
                  }
                />
              </td>
            ))}
          </tr>
        </tbody>
      </table>
      <Link to="/config/volume-history">View detail</Link>
    </>
  );
};

export default Component;
