import AuthRejectConfig from "../component/configPage/AuthRejectRuleConfig";
import ApiConnector from "../connector/ApiConnector";
import CountryRuleConfig from "../component/configPage/CountryRuleConfig";
import CustomerHistoryRuleConfig from "../component/configPage/CustomerHistoryRuleConfig";
import DaytimeRuleConfig from "../component/configPage/DaytimeRuleConfig";
import HotspotRuleConfig from "../component/configPage/HotspotRuleConfig";
import OdometerRuleConfig from "../component/configPage/OdometerRuleConfig";
import TresholdConfig from "../component/configPage/TresholdConfig";
import VelocityRuleConfig from "../component/configPage/VelocityRuleConfig";
import VolumeRuleConfig from "../component/configPage/VolumeRuleConfig";
import WeightConfig from "../component/configPage/WeightConfig";
import { useEffect } from "react";
import { useImmer } from "use-immer";
import RootConfig from "../component/RootConfig";
import CustomerOriginRuleConfig from "../component/configPage/CustomerOriginRuleConfig";
import CardAgeRuleConfig from "../component/configPage/CardAgeRuleConfig";
import Note from "../component/Note";
import { useHistory, useLocation } from "react-router-dom";
import LocationService from "../service/RouteUtilService";
import RouteUtilService from "../service/RouteUtilService";
import ConfigService from "../service/ConfigService";
import OdometerHistoryRuleConfig from "../component/configPage/OdometerHistoryRuleConfig";
import FacilityRuleConfig from "../component/configPage/FacilityRuleConfig";
import RejectHistoryRuleConfig from "../component/configPage/RejectHistoryRuleConfig";
import VolumeHistoryRuleConfig from "../component/configPage/VolumeHistoryRuleConfig";
import AutoAllowRuleConfig from "../component/configPage/AutoAllowRuleConfig";
import RecentHotspotRuleConfig from "../component/configPage/RecentHotspotRuleConfig";
import CustomerCountryHistoryRuleConfig from "../component/configPage/CustomerCountryHistoryRuleConfig";
import CustomerFacilityHistoryRuleConfig from "../component/configPage/CustomerFacilityHistoryRuleConfig";
import OdometerIncrementHistoryRuleConfig from "../component/configPage/OdometerIncrementHistoryRuleConfig";
import OdometerLengthHistoryRuleConfig from "../component/configPage/OdometerLengthHistoryRuleConfig";
import SimilarOdometerRuleConfig from "../component/configPage/SimilarOdometerRuleConfig";
import ConfigSelectorRuleConfig from "../component/configPage/ConfigSelectorRuleConfig";
import SuddenHotspotVisitRuleConfig from "../component/configPage/SuddenHotspotVisitRuleConfig";

const Component = (props) => {
  const [latestConfig, updateLatestConfig] = useImmer(null);
  const [data, updateData] = useImmer(null);
  const [generalConfigData, updateGeneralConfigData] = useImmer(null);
  const history = useHistory();
  const location = useLocation();

  useEffect(async () => {
    const id = LocationService.getParam(location, "id");

    const isPageInitialized = latestConfig;
    if (!id || !isPageInitialized) {
      const config = await ApiConnector.getLatestConfig();
      updateLatestConfig(config);
      updateData(config);

      const generalConfig = await ApiConnector.getLatestGeneralConfig();
      updateGeneralConfigData(generalConfig);
    }

    if (id) {
      const config = await ApiConnector.getConfig(id);
      updateData(config);
    }
  }, [location]);

  const loadPreviousConfig = () => {
    let id = Number(LocationService.getParam(location, "id"));
    id = id ? id : latestConfig.id;
    let newLocation = LocationService.setParam(location, "id", id - 1);
    history.push(newLocation);
  };

  const loadNextConfig = () => {
    let id = Number(LocationService.getParam(location, "id"));
    if (!id) {
      console.warn(
        "Can't load next config because current config is the latest one.",
      );
      return;
    }
    let newLocation = LocationService.setParam(location, "id", id + 1);
    history.push(newLocation);
  };

  const createConfig = async () => {
    const newConfig = await ConfigService.createConfig(data, latestConfig.id);
    if (!newConfig) {
      return;
    }
    updateLatestConfig(newConfig);
    updateData(newConfig);
    history.push(RouteUtilService.deleteParam(location, "id"));
  };

  const isLastConfig = () => {
    return latestConfig.id === data.id;
  };

  return (
    <>
      <header className="subheader">
        <h1>Rules</h1>
      </header>
      <main>
        {data && generalConfigData && (
          <>
            <div className="row">
              <div className="col">
                <h2>Rules config</h2>
              </div>
              <div className="col-4">
                <h2 className="float-right">
                  <a
                    className="fa-solid fa-angle-left  "
                    onClick={(e) => loadPreviousConfig()}
                  />
                  &nbsp;&nbsp;
                  <a
                    className={
                      "fa-solid fa-angle-right " +
                      (isLastConfig() ? "gray" : "")
                    }
                    onClick={(e) => loadNextConfig()}
                  />
                  &nbsp;&nbsp;&nbsp;
                  <Note noteTargetPath="rulesConfig" noteTargetId={data.id} />
                  &nbsp;&nbsp;&nbsp;
                  <a
                    className="fa-regular fa-floppy-disk "
                    onClick={(e) => createConfig()}
                  />
                </h2>
              </div>
            </div>

            <RootConfig
                config={data}
                onIdSelected={(selectedId) => {
                  let newLocation = LocationService.setParam(location, "id", selectedId);
                  history.push(newLocation);
                }}
            ></RootConfig>

            <h3>
              Weights{" "}
              <Note
                className="float-right"
                noteTargetPath="rulesConfig.weight"
                noteTargetId={data.id}
              />
            </h3>
            <WeightConfig
              config={data.riskEvaluation.weightConfig}
              ruleSuppressGeneralConfig={
                generalConfigData.generalConfig.ruleSuppressConfig
              }
              onChange={(e) =>
                updateData((d) => {
                  d.riskEvaluation.weightConfig = e;
                })
              }
            />

            <h3>
              Treshold{" "}
              <Note
                className="float-right"
                noteTargetPath="rulesConfig.treshold"
                noteTargetId={data.id}
              />
            </h3>
            <TresholdConfig
              config={data.riskEvaluation.tresholdConfig}
              onChange={(e) =>
                updateData((d) => {
                  d.riskEvaluation.tresholdConfig = e;
                })
              }
            />

            <h3>
              Auto allow rule{" "}
              <Note
                className="float-right"
                noteTargetPath="rulesConfig.autoAllowRule"
                noteTargetId={data.id}
              />
            </h3>
            <AutoAllowRuleConfig
              config={data.riskEvaluation.autoAllowRuleConfig}
              onChange={(e) =>
                updateData((d) => {
                  d.riskEvaluation.autoAllowRuleConfig = e;
                })
              }
            />

            <h3>
              Card age rule{" "}
              <Note
                className="float-right"
                noteTargetPath="rulesConfig.cardAgeRule"
                noteTargetId={data.id}
              />
            </h3>
            <CardAgeRuleConfig
              config={data.riskEvaluation.cardAgeRuleConfig}
              onChange={(e) =>
                updateData((d) => {
                  d.riskEvaluation.cardAgeRuleConfig = e;
                })
              }
            />

            <h3>
              Country rule{" "}
              <Note
                className="float-right"
                noteTargetPath="rulesConfig.countryRule"
                noteTargetId={data.id}
              />
            </h3>
            <CountryRuleConfig
              config={data.riskEvaluation.countryRuleConfig}
              onChange={(e) =>
                updateData((d) => {
                  d.riskEvaluation.countryRuleConfig = e;
                })
              }
            />

            <h3>
              Customer country history rule{" "}
              <Note
                className="float-right"
                noteTargetPath="rulesConfig.customerCountryHistoryRule"
                noteTargetId={data.id}
              />
            </h3>
            <CustomerCountryHistoryRuleConfig
              config={data.riskEvaluation.customerCountryHistoryRuleConfig}
              customerHistoryConfig={
                data.riskEvaluation.customerHistoryRuleConfig
              }
              onChange={(e) =>
                updateData((d) => {
                  d.riskEvaluation.customerCountryHistoryRuleConfig = e;
                })
              }
            />

            <h3>
              Customer facility history rule{" "}
              <Note
                className="float-right"
                noteTargetPath="rulesConfig.customerFacilityHistoryRule"
                noteTargetId={data.id}
              />
            </h3>
            <CustomerFacilityHistoryRuleConfig
              customerHistoryConfig={
                data.riskEvaluation.customerHistoryRuleConfig
              }
              onChange={(e) =>
                updateData((d) => {
                  d.riskEvaluation.customerCountryHistoryRuleConfig = e;
                })
              }
            />

            <h3>
              Customer history rule{" "}
              <Note
                className="float-right"
                noteTargetPath="rulesConfig.customerHistoryRule"
                noteTargetId={data.id}
              />
            </h3>
            <CustomerHistoryRuleConfig
              config={data.riskEvaluation.customerHistoryRuleConfig}
              onChange={(e) =>
                updateData((d) => {
                  d.riskEvaluation.customerHistoryRuleConfig = e;
                })
              }
            />

            <h3>
              Customer origin rule{" "}
              <Note
                className="float-right"
                noteTargetPath="rulesConfig.customerOriginRule"
                noteTargetId={data.id}
              />
            </h3>
            <CustomerOriginRuleConfig
              config={data.riskEvaluation.customerOriginRuleConfig}
              onChange={(e) =>
                updateData((d) => {
                  d.riskEvaluation.customerOriginRuleConfig = e;
                })
              }
            />

            <h3>
              Daytime rule{" "}
              <Note
                className="float-right"
                noteTargetPath="rulesConfig.daytimeRule"
                noteTargetId={data.id}
              />
            </h3>
            <DaytimeRuleConfig
              config={data.riskEvaluation.daytimeRuleConfig}
              onChange={(e) =>
                updateData((d) => {
                  d.riskEvaluation.daytimeRuleConfig = e;
                })
              }
            />

            <h3>
              Facility rule{" "}
              <Note
                className="float-right"
                noteTargetPath="rulesConfig.facilityRule"
                noteTargetId={data.id}
              />
            </h3>
            <FacilityRuleConfig
              config={data.riskEvaluation.facilityRuleConfig}
              onChange={(e) =>
                updateData((d) => {
                  d.riskEvaluation.facilityRuleConfig = e;
                })
              }
            />

            <h3>
              Hotspot rule{" "}
              <Note
                className="float-right"
                noteTargetPath="rulesConfig.hotspotRule"
                noteTargetId={data.id}
              />
            </h3>
            <HotspotRuleConfig
              config={data.riskEvaluation.hotspotRuleConfig}
              onChange={(e) =>
                updateData((d) => {
                  d.riskEvaluation.hotspotRuleConfig = e;
                })
              }
            />

            <h3>
              Odometer history rule{" "}
              <Note
                className="float-right"
                noteTargetPath="rulesConfig.odometerHistoryRule"
                noteTargetId={data.id}
              />
            </h3>
            <OdometerHistoryRuleConfig
              config={data.riskEvaluation.odometerHistoryRuleConfig}
              onChange={(e) =>
                updateData((d) => {
                  d.riskEvaluation.odometerHistoryRuleConfig = e;
                })
              }
            />

            <h3>
              Odometer increment history rule{" "}
              <Note
                className="float-right"
                noteTargetPath="rulesConfig.odometerIncrementHistoryRule"
                noteTargetId={data.id}
              />
            </h3>
            <OdometerIncrementHistoryRuleConfig
              config={data.riskEvaluation.odometerIncrementHistoryRuleConfig}
              odometerHistoryRuleConfig={
                data.riskEvaluation.odometerHistoryRuleConfig
              }
              onChange={(e) =>
                updateData((d) => {
                  d.riskEvaluation.odometerIncrementHistoryRuleConfig = e;
                })
              }
            />

            <h3>
              Odometer length history rule{" "}
              <Note
                className="float-right"
                noteTargetPath="rulesConfig.odometerLengthHistoryRule"
                noteTargetId={data.id}
              />
            </h3>
            <OdometerLengthHistoryRuleConfig
              config={data.riskEvaluation.odometerLengthHistoryRuleConfig}
              odometerHistoryRuleConfig={
                data.riskEvaluation.odometerHistoryRuleConfig
              }
              onChange={(e) =>
                updateData((d) => {
                  d.riskEvaluation.odometerLengthHistoryRuleConfig = e;
                })
              }
            />

            <h3>
              Odometer rule{" "}
              <Note
                className="float-right"
                noteTargetPath="rulesConfig.odometerRule"
                noteTargetId={data.id}
              />
            </h3>
            <OdometerRuleConfig
              config={data.riskEvaluation.odometerRuleConfig}
              onChange={(e) =>
                updateData((d) => {
                  d.riskEvaluation.odometerRuleConfig = e;
                })
              }
            />

            <h3>
              Recent hotspot rule{" "}
              <Note
                className="float-right"
                noteTargetPath="rulesConfig.recentHotspotRule"
                noteTargetId={data.id}
              />
            </h3>
            <RecentHotspotRuleConfig
              config={data.riskEvaluation.recentHotspotRuleConfig}
              onChange={(e) =>
                updateData((d) => {
                  d.riskEvaluation.recentHotspotRuleConfig = e;
                })
              }
            />

            <h3>
              Reject history rule{" "}
              <Note
                className="float-right"
                noteTargetPath="rulesConfig.rejectHistoryRule"
                noteTargetId={data.id}
              />
            </h3>
            <RejectHistoryRuleConfig
              config={data.riskEvaluation.rejectHistoryRuleConfig}
              onChange={(e) =>
                updateData((d) => {
                  d.riskEvaluation.rejectHistoryRuleConfig = e;
                })
              }
            />

            <h3>
              Similar odometer rule{" "}
              <Note
                className="float-right"
                noteTargetPath="rulesConfig.similarOdometerRule"
                noteTargetId={data.id}
              />
            </h3>
            <SimilarOdometerRuleConfig
              config={data.riskEvaluation.similarOdometerRuleConfig}
              onChange={(e) =>
                updateData((d) => {
                  d.riskEvaluation.similarOdometerRuleConfig = e;
                })
              }
            />

            <h3>
              Sudden hotspot visit rule{" "}
              <Note
                  className="float-right"
                  noteTargetPath="rulesConfig.suddenHotspotVisitRule"
                  noteTargetId={data.id}
              />
            </h3>
            <SuddenHotspotVisitRuleConfig
                config={data.riskEvaluation.suddenHotspotVisitRuleConfig}
                onChange={(e) =>
                    updateData((d) => {
                      d.riskEvaluation.suddenHotspotVisitRuleConfig = e;
                    })
                }
            />

            <h3>
              Velocity rule{" "}
              <Note
                className="float-right"
                noteTargetPath="rulesConfig.velocityRule"
                noteTargetId={data.id}
              />
            </h3>
            <VelocityRuleConfig
              config={data.riskEvaluation.velocityRuleConfig}
              onChange={(e) =>
                updateData((d) => {
                  d.riskEvaluation.velocityRuleConfig = e;
                })
              }
            />

            <h3>
              Volume history rule{" "}
              <Note
                className="float-right"
                noteTargetPath="rulesConfig.volumeHistoryRule"
                noteTargetId={data.id}
              />
            </h3>
            <VolumeHistoryRuleConfig
              config={data.riskEvaluation.volumeHistoryRuleConfig}
              onChange={(e) =>
                updateData((d) => {
                  d.riskEvaluation.volumeHistoryRuleConfig = e;
                })
              }
            />

            <h3>
              Volume rule{" "}
              <Note
                className="float-right"
                noteTargetPath="rulesConfig.volumeRule"
                noteTargetId={data.id}
              />
            </h3>
            <VolumeRuleConfig
              config={data.riskEvaluation.volumeRuleConfig}
              onChange={(e) =>
                updateData((d) => {
                  d.riskEvaluation.volumeRuleConfig = e;
                })
              }
            />

            <h3>
              Any auth reject rule{" "}
              <Note
                className="float-right"
                noteTargetPath="rulesConfig.anyAuthRejectRule"
                noteTargetId={data.id}
              />
            </h3>
            <AuthRejectConfig
              config={data.riskEvaluation.anyAuthRejectRuleConfig}
              onChange={(e) =>
                updateData((d) => {
                  d.riskEvaluation.anyAuthRejectRuleConfig = e;
                })
              }
            />

            <h3>
              Card deck auth reject rule{" "}
              <Note
                className="float-right"
                noteTargetPath="rulesConfig.cardDeckAuthRejectRule"
                noteTargetId={data.id}
              />
            </h3>
            <AuthRejectConfig
              config={data.riskEvaluation.cardDeckAuthRejectRuleConfig}
              onChange={(e) =>
                updateData((d) => {
                  d.riskEvaluation.cardDeckAuthRejectRuleConfig = e;
                })
              }
            />

            <h3>
              Card lock auth reject rule{" "}
              <Note
                className="float-right"
                noteTargetPath="rulesConfig.cardLockAuthRejectRule"
                noteTargetId={data.id}
              />
            </h3>
            <AuthRejectConfig
              config={data.riskEvaluation.cardLockAuthRejectRuleConfig}
              onChange={(e) =>
                updateData((d) => {
                  d.riskEvaluation.cardLockAuthRejectRuleConfig = e;
                })
              }
            />

            <h3>
              Fraud auth reject rule{" "}
              <Note
                className="float-right"
                noteTargetPath="rulesConfig.fraudAuthRejectRule"
                noteTargetId={data.id}
              />
            </h3>
            <AuthRejectConfig
              config={data.riskEvaluation.fraudSuspicionAuthRejectRuleConfig}
              onChange={(e) =>
                updateData((d) => {
                  d.riskEvaluation.fraudSuspicionAuthRejectRuleConfig = e;
                })
              }
            />

            <h3>
              Fuel guard auth reject rule{" "}
              <Note
                className="float-right"
                noteTargetPath="rulesConfig.fuelGuardAuthRejectRule"
                noteTargetId={data.id}
              />
            </h3>
            <AuthRejectConfig
              config={data.riskEvaluation.fuelGuardAuthRejectRuleConfig}
              onChange={(e) =>
                updateData((d) => {
                  d.riskEvaluation.fuelGuardAuthRejectRuleConfig = e;
                })
              }
            />
          </>
        )}
      </main>
    </>
  );
};

export default Component;
