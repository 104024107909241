import UtilService from "./UtilService";

const Component = {
  isThisPageRedirectionFromLoginPage: function () {
    return window.location.href.includes("id_token");
  },
  setIdToken: function (token) {
    window.localStorage.setItem("idToken", token);
  },
  getIdToken: function () {
    return window.localStorage.getItem("idToken");
  },
  clearIdToken: function () {
    window.localStorage.removeItem("idToken");
  },
  setSessionStartTime: function (datetime) {
    window.localStorage.setItem("sessionStartTime", datetime);
  },
  getSessionStartTime: function () {
    return window.localStorage.getItem("sessionStartTime");
  },
  clearSessionStartTime: function () {
    window.localStorage.removeItem("sessionStartTime");
  },
  logout: function () {
    this.clearIdToken();
    this.clearSessionStartTime();
    window.location.href = process.env.REACT_APP_COGNITO_LOGOUT;
  },
  getTokenPayload: function () {
    const encodedToken = this.getIdToken();
    return encodedToken != null
      ? this.mapEncodedTokenToPayloadJson(encodedToken)
      : null;
  },
  redirectToLoginPage: function () {
    window.location.href = process.env.REACT_APP_COGNITO_LOGIN;
  },
  getIdTokenFromUrl: function () {
    return UtilService.getUrlParameterValue("id_token");
  },
  mapEncodedTokenToPayloadJson: function (token) {
    if (!token) {
      return;
    }
    let base64Url = token.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(""),
    );
    return JSON.parse(jsonPayload);
  },
  getUserName: function () {
    return this.getTokenPayload().email.split("@")[0];
  },
};

export default Component;
