import ApiConnector from "../connector/ApiConnector";
import { useEffect } from "react";
import { useImmer } from "use-immer";
import UtilService from "../service/UtilService";
import GetFileViaS3 from "../component/S3FileAnchor";

const Component = (props) => {
  const [newEntry] = useImmer({
    id: 0,
    status: "SEMI_HOTSPOT",
  });
  const [hotspots, updateHotspots] = useImmer(null);
  const [merchantInfos, updateMerchantInfos] = useImmer(null);
  const [csvUrl, updateCsvUrl] = useImmer("");

  useEffect(async () => {
    await loadHotspots();
  }, []);

  const loadHotspots = async () => {
    let newHotspots = await ApiConnector.getMerchantHotspots();
    newHotspots = newHotspots.sort((a, b) => a.id.localeCompare(b.id));
    newHotspots = newHotspots.sort((a, b) => a.status.localeCompare(b.status));
    newHotspots = newHotspots.sort(
      (a, b) => a.isCreatedByAutoUpdate - b.isCreatedByAutoUpdate,
    );

    const merchantCodes = newHotspots.map((h) => h.id);
    const merchantInfos = await ApiConnector.getMerchantInfos(merchantCodes);

    updateHotspots(newHotspots);
    updateMerchantInfos(merchantInfos);

    // Pre-generate csv file so it can be downloaded on single click

    const csvRows = newHotspots.map((h) => {
      h.merchantName = merchantInfos
        .filter((mi) => mi.id === h.id)
        .map((mi) => mi.name)[0];
      return h;
    });

    const csvObjectUrl =
      UtilService.convertJsonArrayToDownloadableCsvFileUrl(csvRows);
    updateCsvUrl(csvObjectUrl);
  };

  const createCsv = () => {
    const presignedUrl = ApiConnector.generateMerchantInfoCsvToS3();
    return presignedUrl;
  };

  const save = async () => {
    const duplicateIds = hotspots
      .map((h) => h.id + "")
      .filter((id, i, a) => a.indexOf(id) !== i);
    console.log(duplicateIds);
    if (duplicateIds.length > 0) {
      window.alert(
        "New hotspots can't be saved because the list contains duplicities: " +
          duplicateIds,
      );
      return;
    }
    const result = window.confirm(
      "New hotspots will be set. This operation can't be undone.",
    );
    if (result) {
      await ApiConnector.replaceMerchantHotspots(hotspots);
      loadHotspots();
    }
  };

  return (
    <>
      <header className="subheader">
        <h1>Config - Hotspot rule </h1>
      </header>
      <main>
        {hotspots && merchantInfos && (
          <>
            <div className="row">
              <div className="col">
                <h2>Merchant hotspots</h2>
              </div>
              <div className="col">
                <h2 className="float-right">
                  <a className="fa-regular fa-floppy-disk" onClick={save}></a>
                </h2>
              </div>
            </div>
            <a href={csvUrl} download={"hotspots.csv"} title="Download as CSV">
              Export Merchant Hotspots
            </a>
            <br />
            <GetFileViaS3
              caption={"Export Merchant Infos"}
              className=""
              onFirstClick={() => createCsv()}
            ></GetFileViaS3>
            <table className="config-table-double table-with-button">
              <thead>
                <tr>
                  <th>Merchant code</th>
                  <th>Status</th>
                  <th>
                    Remaining <small>[hours]</small>
                  </th>
                  <th>Created by auto update</th>
                  <th>Merchant name</th>
                  <th>
                    <a
                      className="fa-solid fa-plus"
                      onClick={(e) =>
                        updateHotspots((d) => {
                          d.unshift(newEntry);
                        })
                      }
                    ></a>
                  </th>
                </tr>
              </thead>
              <tbody>
                {hotspots.map((hotspot, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="text"
                        value={hotspot.id}
                        onChange={(e) =>
                          updateHotspots((d) => {
                            d[index].id = e.target.value;
                          })
                        }
                      />
                    </td>
                    <td>
                      <select
                        value={hotspot.status}
                        onChange={(e) =>
                          updateHotspots((d) => {
                            d[index].status = e.target.value;
                          })
                        }
                      >
                        <option value="SEMI_HOTSPOT">SEMI_HOTSPOT</option>
                        <option value="HOTSPOT">HOTSPOT</option>
                        <option value="RECENT_SEMI_HOTSPOT">
                          RECENT_SEMI_HOTSPOT
                        </option>
                        <option value="RECENT_HOTSPOT">RECENT_HOTSPOT</option>
                      </select>
                    </td>
                    <td>
                      {!hotspot.isCreatedByAutoUpdate && (
                        <span className="inline">
                          <span>
                            <input
                              type="number"
                              value={UtilService.mapIsoOffsetDateTimeStringToHoursRemaining(
                                hotspot.expirationTime,
                              )}
                              onChange={(e) =>
                                updateHotspots((d) => {
                                  d[index].expirationTime =
                                    UtilService.mapHoursRemainingToIsoOffsetDateTimeString(
                                      e.target.value,
                                    );
                                })
                              }
                              disabled={hotspot.isCreatedByAutoUpdate}
                            />
                          </span>
                          <span>
                            &nbsp;
                            <a
                              className="fa-solid fa-eraser"
                              onClick={() =>
                                updateHotspots((d) => {
                                  d[index].expirationTime = null;
                                })
                              }
                            ></a>
                          </span>
                        </span>
                      )}
                    </td>
                    <td>{hotspot.isCreatedByAutoUpdate ? "YES" : "-"}</td>
                    <td className="column-hide-overflow">
                      {merchantInfos
                        .filter((mi) => mi.id === hotspot.id)
                        .map((mi) => mi.name)}
                    </td>
                    <td>
                      <a
                        className="fa-solid fa-trash-can"
                        onClick={(e) =>
                          updateHotspots((d) => {
                            d.splice(index, 1);
                          })
                        }
                      ></a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </main>
    </>
  );
};

export default Component;
