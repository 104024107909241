import { useImmer } from "use-immer";
import { useEffect } from "react";
import _ from "lodash-contrib";
import ReportPageTransactionTableHeader from "./TransactionTableHeaderValueReportPage";
import ReportPageService from "../../service/ReportPageService";

const Component = ({ transactions, displayedTransactionAttributes }) => {
  const [rows, updateRows] = useImmer([]);

  useEffect(() => {
    let tmpRows = [];
    for (let transaction of transactions) {
      let tmpRow = {};

      for (let attributeList of displayedTransactionAttributes) {
        const value = _.get(transaction, attributeList);
        _.set(tmpRow, attributeList, value);
      }

      tmpRows.push(tmpRow);
    }

    updateRows(tmpRows);
  }, [transactions, displayedTransactionAttributes]);

  return (
    <>
      <table className="config-table-double table-scrollable">
        <thead>
          <tr>
            {displayedTransactionAttributes.map((attributeArr, idx) => (
              <th key={idx}>
                <ReportPageTransactionTableHeader
                  headerNameArr={attributeArr}
                ></ReportPageTransactionTableHeader>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((transaction, idx) => (
            <tr key={idx}>
              {displayedTransactionAttributes.map((attrList, idx1) => (
                <td key={idx1} className="use-mono-font">
                  {ReportPageService.renderPrettyValue(
                    _.get(transaction, attrList),
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default Component;
