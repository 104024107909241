import { useImmer } from "use-immer";
import { useEffect } from "react";
import UtilService from "../service/UtilService";
import ConfigSelectorRuleConfig from "./configPage/ConfigSelectorRuleConfig";

const Component = ({ config, onIdSelected }) => {
  const [data, updateData] = useImmer(config);

  useEffect(() => {
    updateData(config);
  }, [config]);

  return (
    <>
      <table className="config-table">
        <thead>
          <tr>
            <th>Attribute</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Id</td>
            <td>
              <ConfigSelectorRuleConfig
                  currentConfigId={data.id}
                  onIdSelected={onIdSelected}
              ></ConfigSelectorRuleConfig>
            </td>
          </tr>
          <tr>
            <td>Created</td>
            <td>{UtilService.isoDateToReadable(data.created)}</td>
          </tr>
          <tr>
            <td>User id</td>
            <td>{data.userId}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default Component;
