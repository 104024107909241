import { useImmer } from "use-immer";
import { useEffect } from "react";

const Component = ({ config, onChange }) => {
  const [data, updateData] = useImmer(config);

  useEffect(() => {
    onChange(data);
  }, [data]);

  useEffect(() => {
    updateData(config);
  }, [config]);

  return (
    <>
      <table className="config-table">
        <thead>
          <tr>
            <th>Attribute</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              History lookup min <small>[days]</small>
            </td>
            <td>
              <input
                type="number"
                min={-365}
                max={data.daysMax - 1}
                value={data.daysMin}
                onChange={(e) =>
                  updateData((d) => {
                    d.daysMin = e.target.value;
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>
              History lookup max <small>[days]</small>
            </td>
            <td>
              <input
                type="number"
                min={data.daysMin + 1}
                max={0}
                value={data.daysMax}
                onChange={(e) =>
                  updateData((d) => {
                    d.daysMax = e.target.value;
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>Score on new monitored merchant visit</td>
            <td>
              <input
                type="number"
                value={data.onNewMerchantVisitScore}
                step="0.1"
                onChange={(e) =>
                  updateData((d) => {
                    d.onNewMerchantVisitScore = e.target.value;
                  })
                }
              />
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default Component;
