import { useImmer } from "use-immer";
import { useEffect } from "react";
import DayOfWeekCheckboxes from "../DayOfWeekCheckboxes";

const Component = ({ config, onChange }) => {
  const [data, updateData] = useImmer(config);

  useEffect(() => {
    updateData(config);
  }, [config]);

  useEffect(() => {
    onChange(data);
  }, [data]);

  return (
    <>
      <table className="config-table">
        <thead>
          <tr>
            <th>Attribute</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Update on days</td>
            <td>
              <DayOfWeekCheckboxes
                dayOfWeekArray={data.updateAtDays}
                onChange={(e) =>
                  updateData((d) => {
                    d.updateAtDays = e;
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>Update on time [CET]</td>
            <td>
              <input
                type="time"
                value={data.updateAtTime}
                onChange={(e) =>
                  updateData((d) => {
                    d.updateAtTime = e.target.value;
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>History lookup [days]</td>
            <td>
              <input
                type="number"
                value={data.historyLookupDays}
                onChange={(e) =>
                  updateData((d) => {
                    d.historyLookupDays =
                      e.target.value < 0 ? e.target.value : 0;
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>Merchant count per facility codes [at least]</td>
            <td>
              <input
                type="number"
                value={data.merchantCountPerFacilityCodesMin}
                onChange={(e) =>
                  updateData((d) => {
                    d.merchantCountPerFacilityCodesMin =
                      e.target.value > 0 ? e.target.value : 0;
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>Fraud count per facility codes [at least]</td>
            <td>
              <input
                type="number"
                value={data.fraudCountPerFacilityCodesMin}
                onChange={(e) =>
                  updateData((d) => {
                    d.fraudCountPerFacilityCodesMin =
                      e.target.value > 0 ? e.target.value : 0;
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>Maximal fraud quotient per facility codes</td>
            <td>
              <input
                type="number"
                value={data.minQuotientForMaxScore}
                step={0.001}
                onChange={(e) =>
                  updateData((d) => {
                    d.minQuotientForMaxScore =
                      e.target.value > 0 ? e.target.value : 0;
                  })
                }
              />
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default Component;
