import { useImmer } from "use-immer";
import ApiConnector from "../connector/ApiConnector";
import UtilService from "../service/UtilService";
import { useEffect } from "react";

const Component = (props) => {
  const [refuelingStatistics, updateRefuelingStatistics] = useImmer(null);

  useEffect(async () => {
    const result = await ApiConnector.getRefuelingVolumeByPercentile();
    result.statistics.sort(
      (a, b) => b.percentileVolumes[0] - a.percentileVolumes[0],
    );
    updateRefuelingStatistics(result);
  }, []);

  return (
    <>
      <header className="subheader">
        <h1>Admin - Volume rule auto update</h1>
      </header>
      <main>
        <div className="row">
          <div className="col">
            <h2>Refueling volume statistics</h2>
          </div>
          <div className="col-4">
            <h2 className="float-right">&nbsp;</h2>
          </div>
        </div>
        {refuelingStatistics && (
          <>
            <details>
              <summary>Parameters</summary>
              <table>
                <tbody>
                  <tr>
                    <td>Transtaction date min [UTC]</td>
                    <td>
                      {UtilService.isoDateToReadable(
                        refuelingStatistics.trnDateMin,
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Transtaction date max [UTC]</td>
                    <td>
                      {UtilService.isoDateToReadable(
                        refuelingStatistics.trnDateMax,
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Country codes</td>
                    <td>
                      {refuelingStatistics.statistics
                        .filter((s) => s.isFraud === null)
                        .map((s) => s.countryCode + ", ")}
                    </td>
                  </tr>
                  <tr>
                    <td>History lookup per PAN [minutes]</td>
                    <td>{refuelingStatistics.historyLookupMinutes}</td>
                  </tr>
                </tbody>
              </table>
              <p>
                Statistics are updated once per day based on latest Volume rule
                and Volume auto update config.
              </p>
            </details>

            <table>
              <thead>
                <tr>
                  <th rowSpan={2}>Country code</th>
                  <th
                    colSpan={
                      refuelingStatistics.statistics[0].percentiles.length + 1
                    }
                    style={{ textAlign: "center" }}
                  >
                    Percentiles [L]
                  </th>
                </tr>
                <tr>
                  {refuelingStatistics.statistics[0].percentiles.map(
                    (percentile) => (
                      <th>
                        {percentile === 0.5
                          ? "Median"
                          : Math.floor(percentile * 100) + "%"}{" "}
                      </th>
                    ),
                  )}
                  <th>Fraud median</th>
                </tr>
              </thead>
              <tbody>
                {refuelingStatistics.statistics
                  .filter((s) => s.isFraud === null)
                  .map((statistic) => (
                    <tr>
                      <td>{statistic.countryCode}</td>
                      {statistic.percentileVolumes.map((volume) => (
                        <td>{volume}</td>
                      ))}
                      <td>
                        {
                          refuelingStatistics.statistics.find(
                            (s) =>
                              s.isFraud === true &&
                              s.countryCode === statistic.countryCode,
                          ).percentileVolumes[0]
                        }
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </>
        )}
      </main>
    </>
  );
};

export default Component;
