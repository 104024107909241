import { useImmer } from "use-immer";
import { useEffect } from "react";

const Component = ({ config, onChange }) => {
  const [data, updateData] = useImmer(config);

  useEffect(() => {
    updateData(config);
  }, [config]);

  useEffect(() => {
    onChange(data);
  }, [data]);

  return (
    <>
      <table className="config-table">
        <thead>
          <tr>
            <th>Attribute</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>History lookup min [days]</td>
            <td>
              <input
                type="number"
                value={data.historyLookupDaysMin}
                onChange={(e) =>
                  updateData((d) => {
                    d.historyLookupDaysMin = e.target.value;
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>History lookup max [days]</td>
            <td>
              <input
                  type="number"
                  value={data.historyLookupDaysMax}
                  onChange={(e) =>
                      updateData((d) => {
                        d.historyLookupDaysMax = e.target.value;
                      })
                  }
              />
            </td>
          </tr>
          <tr>
            <td>Score</td>
            <td>
              <input
                  type="number"
                  value={data.suddenHotspotVisitScore}
                  onChange={(e) =>
                      updateData((d) => {
                        d.suddenHotspotVisitScore = e.target.value;
                      })
                  }
              />
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default Component;
