import UtilService from "../../service/UtilService";

const Component = ({ headerNameArr }) => {
  return (
    <>
      {headerNameArr.length === 1 && (
        <>
          Transaction
          <br />
          {UtilService.camelCaseToReadable(headerNameArr[0])}
        </>
      )}
      {headerNameArr.length === 2 && (
        <>
          {UtilService.camelCaseToReadable(headerNameArr[0])}
          <br />
          {UtilService.camelCaseToReadable(headerNameArr[1])}
        </>
      )}
      {headerNameArr.length === 3 && (
        <>
          {UtilService.camelCaseToReadable(headerNameArr[0])}
          <br />
          {UtilService.camelCaseToReadable(headerNameArr[2])}
        </>
      )}
    </>
  );
};

export default Component;
