import { HashRouter, Link, Route, Switch } from "react-router-dom";
import ReportPage from "./page/ReportPage";
import DashboardPage from "./page/DashboardPage";
import HomePage from "./page/HomePage";
import ConfigPage from "./page/ConfigPage";
import SimulationPage from "./page/SimulationPage";
import ImportPage from "./page/ImportPage";
import AdminPage from "./page/AdminPage";
import { useEffect, useState } from "react";
import AuthService from "./service/AuthService";
import InactivityLogout from "./service/InactivityAuthService";
import OdometerConfigPage from "./page/OdometerConfigPage";
import HotspotConfigPage from "./page/HotspotConfigPage";
import VolumeAutoUpdateAdminPage from "./page/VolumeAutoUpdateAdminPage";
import AutoDeclineAdminPage from "./page/AutoDeclineAdminPage";
import NotePage from "./page/NotePage";
import VolumeHistoryConfigPage from "./page/VolumeHistoryConfigPage";
import AutoAllowConfigPage from "./page/AutoAllowConfigPage";
import AuditlogPage from "./page/AuditlogPage";
import ApiConnector from "./connector/ApiConnector";
import moment from "moment";

function App() {
  const [tokenPayload] = useState(() => AuthService.getTokenPayload());

  useEffect(() => {
    redirectToLoginPageIfTokenNotPresent();
    InactivityLogout.runLogoutOnInactivityBackgroundJob();
  }, []);

  const redirectToLoginPageIfTokenNotPresent = () => {
    if (AuthService.isThisPageRedirectionFromLoginPage()) {
      const idToken = AuthService.getIdTokenFromUrl();
      AuthService.setIdToken(idToken);
      AuthService.setSessionStartTime(moment().toISOString());
      ApiConnector.logUserLoginAuditlog().then(
        (e) => (window.location.href = "/"),
      );
    } else if (tokenPayload == null) {
      // User is not signed in
      AuthService.redirectToLoginPage();
    }
  };

  return (
    <>
      <HashRouter>
        {tokenPayload && (
          <>
            <header>
              <nav className="noprint sticky">
                <ul className="nav">
                  <li>
                    <Link to="/">
                      <img src="ewlogo.png" alt="Logo" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/dashboard" className="nav-button">
                      Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link to="/report" className="nav-button">
                      Report
                    </Link>
                  </li>
                  <li>
                    <Link to="/config" className="nav-button">
                      Rules
                    </Link>
                  </li>
                  <li>
                    <Link to="/simulation" className="nav-button">
                      Simulation
                    </Link>
                  </li>
                  <li>
                    <Link to="/import" className="nav-button">
                      Import
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin" className="nav-button">
                      Admin
                    </Link>
                  </li>
                  {tokenPayload && (
                    <li
                      className="float-right sticky"
                      style={{ paddingTop: "1.2rem" }}
                    >
                      <a
                        className="nav-button"
                        onClick={() => AuthService.logout()}
                      >
                        Logout <small>({AuthService.getUserName()})</small>
                      </a>
                    </li>
                  )}
                </ul>
              </nav>
            </header>

            <Switch>
              {/* Pages - present in menu */}

              <Route exact path="/">
                <HomePage />
              </Route>
              <Route exact path="/dashboard">
                <DashboardPage />
              </Route>
              <Route exact path="/report">
                <ReportPage />
              </Route>
              <Route exact path="/config">
                <ConfigPage />
              </Route>
              <Route exact path="/simulation">
                <SimulationPage />
              </Route>
              <Route exact path="/import">
                <ImportPage />
              </Route>
              <Route exact path="/admin">
                <AdminPage />
              </Route>

              {/* Sub-pages - not present in menu */}

              <Route exact path="/auditlog">
                <AuditlogPage />
              </Route>
              <Route exact path="/config/odometer">
                <OdometerConfigPage />
              </Route>
              <Route exact path="/config/volume-history">
                <VolumeHistoryConfigPage />
              </Route>
              <Route exact path="/config/auto-allow">
                <AutoAllowConfigPage />
              </Route>
              <Route exact path="/config/hotspot">
                <HotspotConfigPage />
              </Route>
              <Route exact path="/admin/volume-auto-update">
                <VolumeAutoUpdateAdminPage />
              </Route>
              <Route exact path="/admin/auto-decline">
                <AutoDeclineAdminPage />
              </Route>
              <Route exact path="/note">
                <NotePage />
              </Route>
            </Switch>
          </>
        )}
      </HashRouter>
    </>
  );
}

export default App;
