import { useImmer } from "use-immer";
import { useEffect } from "react";

const Component = ({ config, odometerHistoryRuleConfig, onChange }) => {
  const [data, updateData] = useImmer(config);

  useEffect(() => {
    updateData(config);
  }, [config]);

  useEffect(() => {
    onChange(data);
  }, [data]);

  return (
    <>
      <table className="config-table">
        <thead>
          <tr>
            <th>Attribute</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Unrecognized odometer score</td>
            <td>
              <input
                type="number"
                value={data.unrecognizedValueScore}
                onChange={(e) =>
                  updateData((d) => {
                    d.unrecognizedValueScore = e.target.value;
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>
              History lookup min <small>[days]</small>
            </td>
            <td>{odometerHistoryRuleConfig.historyLookupDaysMin}</td>
          </tr>
          <tr>
            <td>
              History lookup max <small>[days]</small>
            </td>
            <td>{odometerHistoryRuleConfig.historyLookupDaysMax}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default Component;
