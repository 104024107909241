import UtilService from "../../service/UtilService";
import { useImmer } from "use-immer";
import { useEffect } from "react";

const Component = ({ config, onChange }) => {
  const scoreTemplate = {
    historyLookupHoursMin: 0,
    historyLookupHoursMax: 0,
    countMin: 0,
    countMax: 0,
    scoreOnMatch: 0,
  };
  const [data, updateData] = useImmer(config);

  useEffect(() => {
    updateData(config);
  }, [config]);

  useEffect(() => {
    onChange(data);
  }, [data]);

  const addScore = () => {
    updateData((draft) => {
      draft.scores.push(structuredClone(scoreTemplate));
    });
  };

  const removeScore = (index) => {
    updateData((draft) => {
      draft.scores.splice(index, 1);
    });
  };

  return (
    <>
      <table className="config-table">
        <thead>
          <tr>
            <th>Attribute</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Reject reason codes</td>
            <td>
              <input
                type="text"
                value={UtilService.arrayToCsv(data.rejectReasonCodes)}
                onChange={(e) =>
                  updateData((d) => {
                    d.rejectReasonCodes = UtilService.csvToStringArray(
                      e.target.value,
                    );
                  })
                }
              />
            </td>
          </tr>
        </tbody>
      </table>
      <table className="config-table-double table-with-button">
        <thead>
          <tr>
            <th>
              History min <small>[hours]</small>
            </th>
            <th>
              History max <small>[hours]</small>
            </th>
            <th>Count min</th>
            <th>Count max</th>
            <th>Score</th>
            <th>
              <a className="fa-solid fa-plus" onClick={addScore}></a>
            </th>
          </tr>
        </thead>
        <tbody>
          {data.scores.map((score, index) => (
            <tr key={index}>
              <td>
                <input
                  type="number"
                  max={score.historyLookupHoursMax}
                  value={score.historyLookupHoursMin}
                  onChange={(e) =>
                    updateData((d) => {
                      d.scores[index].historyLookupHoursMin = e.target.value;
                    })
                  }
                />
              </td>
              <td>
                <input
                  type="number"
                  min={score.historyLookupHoursMin}
                  max={0}
                  value={score.historyLookupHoursMax}
                  onChange={(e) =>
                    updateData((d) => {
                      d.scores[index].historyLookupHoursMax = e.target.value;
                    })
                  }
                />
              </td>
              <td>
                <input
                  type="number"
                  min={0}
                  max={score.countMax}
                  value={score.countMin}
                  onChange={(e) =>
                    updateData((d) => {
                      d.scores[index].countMin = e.target.value;
                    })
                  }
                />
              </td>
              <td>
                <input
                  type="number"
                  min={score.countMin}
                  max={10000}
                  value={score.countMax}
                  onChange={(e) =>
                    updateData((d) => {
                      d.scores[index].countMax = e.target.value;
                    })
                  }
                />
              </td>
              <td>
                <input
                  type="number"
                  min={0}
                  value={score.scoreOnMatch}
                  onChange={(e) =>
                    updateData((d) => {
                      d.scores[index].scoreOnMatch = e.target.value;
                    })
                  }
                />
              </td>
              <td>
                <a
                  className="fa-solid fa-trash-can"
                  onClick={() => removeScore(index)}
                ></a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default Component;
