import axios from "axios";
import Auth from "../service/AuthService";
import AuthService from "../service/AuthService";

const Component = {
  /**
   * Transactions API
   */

  getTransactions: async function ({
    trnDateMin,
    trnDateMax,
    totalRiskMin,
    totalRiskMax,
    transactionTypes,
    pans,
    merchantCodes,
    statuses,
    detailStatuses,
    riskEvaluationStatuses,
    rejectReasonCodes,
    transactionIds,
    isFraud,
    offset,
    pageSize,
    displayCount,
  }) {
    const response = await this.call({
      method: "GET",
      url: process.env.REACT_APP_PABLO_API + "/transactions",
      params: {
        trnDateMin,
        trnDateMax,
        totalRiskMin,
        totalRiskMax,
        transactionTypes,
        pans,
        merchantCodes,
        statuses,
        detailStatuses,
        riskEvaluationStatuses,
        rejectReasonCodes,
        transactionIds,
        isFraud,
        offset,
        pageSize,
        displayCount,
      },
    });
    return await response.data;
  },

  /**
   * Config API
   */

  getLatestConfig: async function () {
    const response = await this.call({
      method: "GET",
      url: process.env.REACT_APP_PABLO_API + "/configs/latest",
    });
    return await response.data;
  },
  getConfig: async function (id) {
    const response = await this.call({
      method: "GET",
      url: process.env.REACT_APP_PABLO_API + "/configs/" + id,
    });
    return await response.data;
  },
  createConfig: async function (config) {
    const response = await this.call({
      method: "POST",
      url: process.env.REACT_APP_PABLO_API + "/configs",
      data: config,
    });
    return response.data;
  },
  getConfigHeaders: async function (idMin, idMax, limit) {
    const response = await this.call({
      method: "GET",
      url: process.env.REACT_APP_PABLO_API + "/configs/headers",
      params: {
        idMin: idMin,
        idMax: idMax,
        limit: limit,
      },
    });
    return await response.data;
  },

  /**
   * General config API
   */

  getLatestGeneralConfig: async function () {
    const response = await this.call({
      method: "GET",
      url: process.env.REACT_APP_PABLO_API + "/general-configs/latest",
    });
    return await response.data;
  },
  createGeneralConfig: async function (config) {
    const response = await this.call({
      method: "POST",
      url: process.env.REACT_APP_PABLO_API + "/general-configs",
      data: config,
    });
    return response.data;
  },

  /**
   * Odometers API
   */

  getOdometers: async function () {
    const response = await this.call({
      method: "GET",
      url: process.env.REACT_APP_PABLO_API + "/odometers",
    });
    return await response.data;
  },
  replaceOdometers: async function (odometers) {
    const response = await this.call({
      method: "POST",
      url: process.env.REACT_APP_PABLO_API + "/odometers/replace",
      data: odometers,
    });
    return await response.data;
  },

  /**
   * Merchant Hotspot API
   */

  getMerchantHotspots: async function () {
    const response = await this.call({
      method: "GET",
      url: process.env.REACT_APP_PABLO_API + "/merchant-hotspots",
    });
    return await response.data;
  },
  replaceMerchantHotspots: async function (hotspots) {
    const response = await this.call({
      method: "POST",
      url: process.env.REACT_APP_PABLO_API + "/merchant-hotspots/replace",
      data: hotspots,
    });
    return await response.data;
  },

  /**
   * Merchant Info API
   */

  getMerchantInfos: async function (merchantCodes) {
    const response = await this.call({
      method: "GET",
      url: process.env.REACT_APP_PABLO_API + "/merchant-infos",
      params: {
        merchantCodes,
      },
    });
    return await response.data;
  },

  /**
   * Import API
   */

  storeTransactionsFromCsv: async function (s3FileName) {
    const response = await this.call({
      method: "POST",
      url:
        process.env.REACT_APP_PABLO_API +
        "/imports/store-transactions-from-csv",
      params: {
        s3FileName: s3FileName,
      },
    });
    return await response.data;
  },
  storeProductsFromCsv: async function (s3FileName) {
    const response = await this.call({
      method: "POST",
      url: process.env.REACT_APP_PABLO_API + "/imports/store-products-from-csv",
      params: {
        s3FileName: s3FileName,
      },
    });
    return await response.data;
  },
  storeFraudsFromCsv: async function (s3FileName) {
    const response = await this.call({
      method: "POST",
      url: process.env.REACT_APP_PABLO_API + "/imports/store-frauds-from-csv",
      params: {
        s3FileName: s3FileName,
      },
    });
    return await response.data;
  },

  /**
   * Export API
   */

  generateRiskEvaluationCsvToS3: async function (trnDateMin, trnDateMax) {
    const response = await this.call({
      method: "POST",
      url:
        process.env.REACT_APP_PABLO_API +
        "/exports/generate-risk-evaluation-csv-to-s3",
      params: {
        trnDateMin: trnDateMin,
        trnDateMax: trnDateMax,
      },
    });
    return await response.data;
  },
  generateMerchantInfoCsvToS3: async function () {
    const response = await this.call({
      method: "POST",
      url:
        process.env.REACT_APP_PABLO_API +
        "/exports/generate-merchant-info-csv-to-s3",
      params: {},
    });
    return await response.data;
  },

  /**
   * Statistics API
   */

  getRefuelingVolumeByPercentile: async function () {
    const response = await this.call({
      method: "GET",
      url:
        process.env.REACT_APP_PABLO_API +
        "/statistics/refueling-volume-by-percentile",
      params: {},
    });
    return await response.data;
  },
  getPanVolumesWithFrauds: async function () {
    const response = await this.call({
      method: "GET",
      url:
        process.env.REACT_APP_PABLO_API + "/statistics/pan-volumes-with-frauds",
      params: {},
    });
    return await response.data;
  },
  getFalsePositiveTotalRisks: async function (trnDateMin, trnDateMax) {
    const response = await this.call({
      method: "GET",
      url:
        process.env.REACT_APP_PABLO_API +
        "/statistics/false-positives-total-risks",
      params: { trnDateMin, trnDateMax },
    });
    return await response.data;
  },

  /**
   * Notes API
   */

  getNotes: async function (targetPathStartsWith, targetIdMax, pageSize) {
    const response = await this.call({
      method: "GET",
      url: process.env.REACT_APP_PABLO_API + "/notes",
      params: {
        targetPathStartsWith,
        targetIdMax,
        pageSize,
      },
    });
    return await response.data;
  },
  createNote: async function (note) {
    const response = await this.call({
      method: "POST",
      url: process.env.REACT_APP_PABLO_API + "/notes",
      data: note,
    });
    return await response.data;
  },
  updateNote: async function (note) {
    const response = await this.call({
      method: "PUT",
      url: process.env.REACT_APP_PABLO_API + "/notes",
      data: note,
    });
    return await response.data;
  },
  deleteNote: async function (note) {
    const response = await this.call({
      method: "DELETE",
      url: process.env.REACT_APP_PABLO_API + "/notes/" + note.id,
      data: note,
    });
    return await response.data;
  },

  /**
   * Actuator API
   */

  getVersion: async function () {
    const response = await this.call({
      method: "GET",
      url: process.env.REACT_APP_PABLO_API + "/actuator/info",
    });

    return response.data.build.version;
  },

  /**
   * Auditlog API
   */

  getAuditlogs: async function (createdMin, createdMax, pageSize) {
    const response = await this.call({
      method: "GET",
      url: process.env.REACT_APP_PABLO_API + "/auditlogs",
      params: { createdMin, createdMax, pageSize },
    });

    return response.data;
  },
  logUserLoginAuditlog: async function () {
    const response = await this.call({
      method: "POST",
      url: process.env.REACT_APP_PABLO_API + "/auditlogs/log-user-login",
    });
    return response.data;
  },

  /**
   * Util methods
   */

  async call(params) {
    const authHeader = Auth.getIdToken() ? "Bearer " + Auth.getIdToken() : null;
    const p = {
      ...params,
      headers: {
        Authorization: authHeader,
        ...params.headers,
      },
      paramsSerializer: { indexes: null },
    };

    try {
      return await axios(p);
    } catch (e) {
      this.handleError(e);
    }
  },
  handleError(e) {
    let msg = "";
    if (e.message.includes("403")) {
      msg =
        "User " +
        AuthService.getUserName() +
        " has no rights to perform this action.";
    } else if (e.message.includes("401")) {
      msg = "User is unauthorized. Please try re-login.";
    } else if (e.message.includes("400")) {
      msg = null; // FIXME
    } else {
      msg =
        "Unknown error happened when calling the server.\n\nError details: " +
        e.message;
    }
    if (msg != null) {
      window.alert(msg);
    }
  },
};

export default Component;
