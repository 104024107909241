import { useImmer } from "use-immer";
import ApiConnector from "../connector/ApiConnector";
import { useEffect, useState } from "react";
import UtilService from "../service/UtilService";
import S3Connector from "../connector/S3Connector";

const Component = (props) => {
  const [s3Files, updateS3Files] = useImmer([]);

  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState("transactions");
  const [isUploadInProgress, setIsUploadInProgress] = useState(false);
  const [status, setStatus] = useState("Nothing to do");

  useEffect(() => {
    loadListOfS3Files();
  }, []);

  const loadListOfS3Files = () => {
    S3Connector.getListOfS3Files().then((t) => {
      t = t.sort((a, b) => b.lastModified.localeCompare(a.lastModified));
      updateS3Files(t);
    });
  };

  const processFile = async () => {
    if (isUploadInProgress) {
      window.alert("There is already upload in progress.");
      return;
    }

    const result = window.confirm(
      "Are you sure you want to store a file type " +
        fileType +
        " " +
        file.name +
        " " +
        "to Pablo? This operation may take up to 20 minutes (depending on size) and once started it cannot be taken back. " +
        "Do not start any other operation during this process and wait until it's finished.",
    );

    if (!result) {
      return;
    }

    try {
      setIsUploadInProgress(true);
      const fileName = file.name.replace(/\s/g, "");
      setStatus("Obtaining url for file upload...");
      const resp = await S3Connector.getS3FileUploadUrl(fileName);
      const uploadUrl = resp.upload_url;
      setStatus("Uploading file...");
      await S3Connector.uploadFileToS3(uploadUrl, file);
      setStatus(
        "Processing file. Notification will be sent to Slack when finished.",
      );
      switch (fileType) {
        case "transactions":
          await ApiConnector.storeTransactionsFromCsv(fileName);
          break;
        case "products":
          await ApiConnector.storeProductsFromCsv(fileName);
          break;
        case "frauds":
          await ApiConnector.storeFraudsFromCsv(fileName);
          break;
        default:
          console.error("Unknown type");
      }
    } catch (e) {
      // Error 503 is returned by Api Gw because of request Timeout.
      // It not cause an issue because Pablo is processing the file anyway.
      // User is notified in Slack channel when processing has finished.
      if (e.response.status !== 503) {
        setStatus("Failed: " + e.message);
        console.log(e);
      }
    }
    setIsUploadInProgress(false);
    loadListOfS3Files();
  };

  return (
    s3Files && (
      <>
        <header className="subheader">
          <h1>Import</h1>
        </header>

        <main>
          <div className="row">
            <div className="col">
              <h2>Import data</h2>
            </div>
            <div className="col">
              <h2 className="float-right">
                <a
                  className="fa-regular fa-floppy-disk"
                  onClick={() => processFile()}
                ></a>
              </h2>
            </div>
          </div>
          <h3 className="float-right">&nbsp;</h3>
          <table className="config-table table-with-button">
            <thead>
              <tr>
                <th>Import type</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <select
                    value={fileType}
                    onChange={(e) => setFileType(e.target.value)}
                  >
                    <option value="transactions">Transactions csv</option>
                    <option value="products">Products csv</option>
                    <option value="frauds">Frauds csv</option>
                  </select>
                </td>
                <td>
                  <input
                    type="file"
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <thead>
              <tr>
                <th>Import status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{status}</td>
              </tr>
            </tbody>
          </table>

          <h3>Uploaded files</h3>

          <table className="config-table table-with-button">
            <thead>
              <tr>
                <th>File name</th>
                <th>Last modified</th>
                <th>Size [Mb]</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {s3Files.map((entry, idx) => (
                <tr key={idx}>
                  <td>{entry.key}</td>
                  <td>{UtilService.isoDateToReadable(entry.lastModified)}</td>
                  <td>{Math.round((entry.size / 1024 / 1024) * 100) / 100}</td>
                  <td></td>
                </tr>
              ))}
            </tbody>
          </table>
        </main>
      </>
    )
  );
};

export default Component;
