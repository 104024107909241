import { useImmer } from "use-immer";
import { useEffect } from "react";
import ApiConnector from "../connector/ApiConnector";
import ActionGeneralConfig from "../component/adminPage/ActionGeneralConfig";
import Note from "../component/Note";
import GeneralConfigService from "../service/GeneralConfigService";

const Component = (props) => {
  const [generalConfig, updateGeneralConfig] = useImmer(null);
  const [statistics, updateStatistics] = useImmer({
    authTransactionCount: 0,
    declinedAuthTransactionCount: 0,
    declinedBlockCardAuthTransactionCount: 0,
    suppressedAuthTransactionCount: 0,
    fraudAuthTransactionCount: 0,
  });

  useEffect(() => {
    loadLatestGeneralConfig();
    loadStatistics();
  }, []);

  useEffect(() => {}, [generalConfig]);

  const loadLatestGeneralConfig = () => {
    ApiConnector.getLatestGeneralConfig().then((t) => {
      updateGeneralConfig(t);
    });
  };

  const createGeneralConfig = async () => {
    const newConfig = await GeneralConfigService.createGeneralConfig(
      generalConfig,
      generalConfig.id,
    );
    if (!newConfig) {
      return;
    }
    updateGeneralConfig(newConfig);
  };

  const loadStatistics = async () => {
    let last24HoursTimestamp = new Date();
    last24HoursTimestamp.setHours(last24HoursTimestamp.getHours() - 24);

    const authTransaction = await ApiConnector.getTransactions({
      trnDateMin: last24HoursTimestamp,
      transactionTypes: ["A"],
      displayCount: true,
      pageSize: 0,
    });
    const declinedAuthTransaction = await ApiConnector.getTransactions({
      trnDateMin: last24HoursTimestamp,
      transactionTypes: ["A"],
      riskEvaluationStatuses: ["DECLINED"],
      displayCount: true,
      pageSize: 0,
    });
    const declinedBlockCardAuthTransaction = await ApiConnector.getTransactions(
      {
        trnDateMin: last24HoursTimestamp,
        transactionTypes: ["A"],
        riskEvaluationStatuses: ["DECLINED_BLOCK_CARD"],
        displayCount: true,
        pageSize: 0,
      },
    );
    const suppressedAuthTransaction = await ApiConnector.getTransactions({
      trnDateMin: last24HoursTimestamp,
      transactionTypes: ["A"],
      detailStatuses: ["APPROVED_BY_PAN_SUPPRESS"],
      displayCount: true,
      pageSize: 0,
    });
    const fraudAuthTransaction = await ApiConnector.getTransactions({
      trnDateMin: last24HoursTimestamp,
      transactionTypes: ["A"],
      isFraud: true,
      displayCount: true,
      pageSize: 0,
    });

    updateStatistics((d) => {
      d.authTransactionCount = authTransaction.count;
      d.declinedAuthTransactionCount = declinedAuthTransaction.count;
      d.declinedBlockCardAuthTransactionCount =
        declinedBlockCardAuthTransaction.count;
      d.suppressedAuthTransactionCount = suppressedAuthTransaction.count;
      d.fraudAuthTransactionCount = fraudAuthTransaction.count;
    });
  };

  return (
    generalConfig && (
      <>
        <header className="subheader">
          <h1>Dashboard</h1>
        </header>
        <main>
          <div className="row">
            <div className="col">
              <div className="row">
                <div className="col">
                  <h2>Statistics</h2>
                </div>
              </div>

              <h3>Last 24 hours</h3>
              <table className="config-table">
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Authorizations</td>
                    <td>{statistics.authTransactionCount}</td>
                  </tr>
                  <tr>
                    <td>Declined</td>
                    <td>{statistics.declinedAuthTransactionCount}</td>
                  </tr>
                  <tr>
                    <td>Declined - blocked card</td>
                    <td>{statistics.declinedBlockCardAuthTransactionCount}</td>
                  </tr>
                  <tr>
                    <td>Pan suppressed</td>
                    <td>{statistics.suppressedAuthTransactionCount}</td>
                  </tr>
                  <tr>
                    <td>Confirmed frauds</td>
                    <td>{statistics.fraudAuthTransactionCount}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col">
              <div className="row">
                <div className="col">
                  <h2>Actions</h2>
                </div>
                <div className="col">
                  <h2 className="float-right">
                    <Note
                      noteTargetPath="generalConfig"
                      noteTargetId={generalConfig.id}
                    />
                    &nbsp;&nbsp;&nbsp;
                    <a
                      className="fa-regular fa-floppy-disk"
                      onClick={createGeneralConfig}
                    ></a>
                  </h2>
                </div>
              </div>
              <ActionGeneralConfig
                config={generalConfig}
                onChange={(config) => updateGeneralConfig(config)}
              ></ActionGeneralConfig>
            </div>
          </div>
        </main>
      </>
    )
  );
};

export default Component;
