import { useImmer } from "use-immer";
import { useEffect } from "react";

const Component = ({ config, onChange }) => {
  const [data, updateData] = useImmer(config);

  useEffect(() => {
    onChange(data);
  }, [data]);

  useEffect(() => {
    updateData(config);
  }, [config]);

  return (
    <>
      <table className="config-table">
        <thead>
          <tr>
            <th>
              Card age <small>[months]</small>
            </th>
            <th>Score</th>
          </tr>
        </thead>
        <tbody>
          {data.cardAgeSemiannualScores.map((score, index) => (
            <tr key={index}>
              <td>
                {index * 6} - {index * 6 + 6 }
              </td>
              <td>
                <input
                  type="number"
                  value={data.cardAgeSemiannualScores[index]}
                  onChange={(e) =>
                    updateData((d) => {
                      d.cardAgeSemiannualScores[index] = e.target.value;
                    })
                  }
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default Component;
