import { useImmer } from "use-immer";
import { useEffect } from "react";
import DayOfWeekCheckboxes from "../DayOfWeekCheckboxes";
import UtilService from "../../service/UtilService";

const Component = ({ config, onChange }) => {
  const [data, updateData] = useImmer(config);

  useEffect(() => {
    updateData(config);
  }, [config]);

  useEffect(() => {
    onChange(data);
  }, [data]);

  return (
    <>
      <table className="config-table">
        <thead>
          <tr>
            <th>Attribute</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Update on days</td>
            <td>
              <DayOfWeekCheckboxes
                dayOfWeekArray={data.updateAtDays}
                onChange={(e) =>
                  updateData((d) => {
                    d.updateAtDays = e;
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>Update on time [CET]</td>
            <td>
              <input
                type="time"
                value={data.updateAtTime}
                onChange={(e) =>
                  updateData((d) => {
                    d.updateAtTime = e.target.value;
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>History lookup [days]</td>
            <td>
              <input
                type="number"
                value={data.historyLookupDays}
                onChange={(e) =>
                  updateData((d) => {
                    d.historyLookupDays =
                      e.target.value < 0 ? e.target.value : 0;
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>New value history lookup [days]</td>
            <td>
              <input
                type="number"
                value={data.newValueHistoryLookupDays}
                onChange={(e) =>
                  updateData((d) => {
                    d.newValueHistoryLookupDays =
                      e.target.value < 0 ? e.target.value : 0;
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>Warm value fraud count [at least]</td>
            <td>
              <input
                type="number"
                value={data.warmValueMinOccurenceCount}
                onChange={(e) =>
                  updateData((d) => {
                    d.warmValueMinOccurenceCount =
                      e.target.value > 0 ? e.target.value : 0;
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>Hot value fraud count [at least]</td>
            <td>
              <input
                type="number"
                value={data.hotValueMinOccurenceCount}
                onChange={(e) =>
                  updateData((d) => {
                    d.hotValueMinOccurenceCount =
                      e.target.value > 0 ? e.target.value : 0;
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>Exclude odometers</td>
            <td>
              <input
                type="text"
                value={UtilService.arrayToCsv(data.excludeOdometers)}
                onChange={(e) =>
                  updateData((d) => {
                    d.excludeOdometers = UtilService.csvToStringArray(
                      e.target.value,
                    );
                  })
                }
              />
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default Component;
