import UtilService from "./UtilService";

const JOIN_STRING = " -> ";

const Component = {
  createNoteRouteUrl: function (
    noteTargetPath,
    noteTargetId,
    isEnforceUserToUpdateNote,
  ) {
    let route = "/note";
    route += noteTargetPath ? "?targetPath=" + noteTargetPath : "";
    route += noteTargetId ? "&targetId=" + noteTargetId : "";
    route += isEnforceUserToUpdateNote
      ? "&isEnforceUserToUpdateNote=" + isEnforceUserToUpdateNote
      : "";
    return route;
  },
  noteTargetPathToReadable: function (targetPath, targetId) {
    let splits = targetPath.split(".");
    let parts = [];
    let isAddTargetId = targetId;
    for (let split of splits) {
      let part = UtilService.camelCaseToReadable(split);
      parts.push(part);

      if (isAddTargetId) {
        parts.push(targetId);
        isAddTargetId = false;
      }
    }
    return parts.join(JOIN_STRING);
  },
};

export default Component;
