import ApiConnector from "../connector/ApiConnector";
import { useImmer } from "use-immer";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const Component = (props) => {
  const [version, updateVersion] = useImmer(null);

  useEffect(async () => {
    updateVersion(await ApiConnector.getVersion());
  }, []);

  return (
    <>
      <header className="subheader">
        <h1>PABLO</h1>
      </header>
      <main>
        <p>
          PABLO is anti-fraud service which measures fraud risk level of
          authorization transactions and decides whether it should be blocked.
        </p>
        <p>
          Documentation:{" "}
          <a href="https://confluence.eurowag.com/display/AFS/PABLO+-+Prediction+and+Block">
            confluence.eurowag.com
          </a>
        </p>
        <p>Version: {version}</p>
        <Link to="/auditlog">View audit log</Link>
      </main>
    </>
  );
};

export default Component;
